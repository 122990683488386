#contact{
    padding-top: 7%;
    z-index: 1;
    position: relative;
    background: color(background-light);
    .wrapper{
        .contact-form{
            padding: 50px 18px 90px;
            
            @include tablet-screen{
                padding: 50px 40px 90px;
            }
            form{
                border-radius: 15px;
                label{
                    margin-top: 20px;
                    display: block;
                    font-weight: font-weight(semi-bold);
                    font-size: 14px;
                    line-height: 17px;

                    &:first-child{
                        margin-top: 28px;
                    }

                    input, textarea{
                        color: #000;
                        font-family: inherit;
                        margin-top: 6px;
                        height: 40px;
                        padding: 10px;
                        border-radius: 8px; 
                        border: none;
                        background: #f3f3f3;
                        width: 100%;
                        display: block;
                        outline: none;
                    }
    
                    textarea{
                        resize: none;
                        height: 150px;
                    }
                }
    
                .btn-submit{
                    float: right;
                    margin-top: 25px;
                }
            }
        }

        @include desktop-screen{
            display: grid;
            grid-template-columns: 1fr 0.9fr;
            grid-template-areas: "social form";
            width: 95%;
            max-width: 1120px;
            .contact-form{
                padding: 50px 40px 20px;
                grid-area: form;
            }
            .socials{
                grid-area: social;
            }
        }
        max-width: 550px;
        width: 97%;
        @include tablet-screen{
            width: 80%;
        }
        border-radius: 10px;
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.16);
        margin: auto;

        h3{
            margin-bottom: 30px;
            &::after{
                content: '';
                height: 4px;
                width: 50px;
                background: color(primary);
                display: block;
            }
        }
        
        .socials{
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            width: 100%;
            background: url(../../images/contact-us.svg) #e4f2ff no-repeat center center;
            background-size: contain;
        }

    }
}
@media (min-width: 320px) and (max-width: 768px) {
    #contact{
        padding-top: 10%;
    };
    #formbutton-button{
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 950px) {
    #formbutton-button{
        display: none !important;
    }
}

