.icp-header-container{
    background-color: #005eb8;
    width: 100%;
    /* padding: 5%; */
    padding-left: 7%;
    padding-right: 7%;
    /* padding-top: 1%; */

    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.icp-header{
    max-width: 50%;
}
.icp-title{
    color: #fff;
    font-weight: 400;
    margin: 0 0 16px;
}
.icp-text{
    color: #fff;
    font-weight: 400;
    margin: 0 0 16px;
}
.text{
    color: #fff !important;
    font-size: 18px;
    font-style: italic;
    line-height: normal;
    margin-bottom: 18px;
}
.icp{
    background-color: #ffd546;
    color: #222;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.78;
    margin-right: 32px;
    padding: 9px 66px 9px 32px;
    position: relative;
}
.icp2{
    background-color: #005eb8;
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.78;
    padding: 8px 32px;
}
.icp-image-wrapper {
    width: 40%;
}
.img{
    height: 617px;
    width: 401px;
}
.main{
    padding-left: 14%;
    padding-right: 12%;
    display: flex;
    width: 100%;
}
.main-service-pages{
    padding-left: 14%;
    padding-right: 12%;
    display: flex;
    width: 100%;
}
.left{
    width: 100%;
}
.right{
    box-sizing: border-box!important;
    direction: inherit;
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: sticky;
    top: 70px;
    visibility: visible;
    box-sizing: inherit!important;
    padding-left: 40px;
    padding-top: 30px;
}
.section-text{
    padding-top: 50px;
    padding-bottom: 50px;
    line-height: 40px;
    font-size: larger;
}
.fl-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}
@media only screen and (max-width:900px) {
    .main{
        width: 100% !important;
        display: flex;
        flex-direction: column-reverse;
    }
    .right{
        position: relative;
        /* padding-bottom: 10px; */
        top: -20px;
    }
    .icp-header-container{
        width: 100%;
       padding-top: 6%;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-left: -7%;
    }
    .icp-header{
        padding-top: 20%;
        max-width: 90%;
    }
    .icp-image-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .left{
        /* background-color: #222; */
        width: 100%;
    }
}
@media only screen and (max-width:433px) {
    .icp-header-container{
        width: 100%;
        padding: 5%;
        display: flex;
        flex-direction: column;
    }
   .icp-image-wrapper {
    /* max-width: 414px; */
    
}
.img{
   height: 600px !important;
    width: 300px;
}
.main{
    width: 100% !important;
    display: flex;
    flex-direction: column-reverse;
}
.icp-image-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
    
}
@media only screen and (max-width:590px) {
    .icp-header-container{
        width: 100%;
       padding-top: 6%;
        display: flex;
        flex-direction: column;
        margin: 0;
        /* padding-left: -7%; */
    }
    .icp-header{
        padding-top: 20%;
        max-width: 90%;
    }
    .img{
        justify-content: center;
       
    }
    .icp-image-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}