.nav-link{
    margin-left: 2px;
    color: #444444;
}
.nav-length{
    min-width: 400px;
    /* background-color: black; */
}
.nav-title{
    font-size: 18px;
    text-align: left;
}
.nav-items-flex{
    padding-bottom: 10px;
    padding-top: 2px;
}
.nav-title-title{
    font-size: 23px;
}
.nav-title-title-connect{
    font-size: 23px;
    font-weight: 600;
}
.nav-items-flex{
    max-width: 400px;
    word-break: break-all;
}