.share-component {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    margin-top: 25px;
    top: unset;
}

.share-component-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    margin-right: 15px;
}

.share-component-link {
    border-radius: 50%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .30);
    color: #0065c5;
    display: flex;
    font-size: 22px;
    height: 40px;
    line-height: 44px;
    margin-bottom: 0;
    margin-right: 10px;
    text-align: center;
    width: 40px;
    align-items: center !important;
    justify-content: center !important;
}

.share-component-link:hover {
    background-color: #005eb8;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .30);
    color: #fff;
}