.modal {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    margin-top: 70px;
  .modal-wrapper{
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 100%;
    @include tablet-screen{
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }
    .modal-close{
        cursor: pointer;
        top: 1.3rem;
        right: 1.3rem;
        width: 1.25rem;
        height: 1.25rem;
        color: color(accent-secondary);
        position: absolute;
        &:hover{
            color: white;
        }
    }
    .modal-container {
        position: relative;
        text-align: center;
        border-radius: 7px;
        background-color: #fefefe;
        margin: auto; 
        border: 1px solid #888;
        max-width: 100%;
        max-height: 100%;
        @media (min-width: 850px) {
            .icon{
                background-image: url(../../images/mail-sent.png);
                background-repeat: no-repeat;
                margin: auto;
                width: 70px;
                height: 70px;
                margin-bottom: .6rem;
                background-size: contain;;
            }
            .modal-body{
                background-image: url(../../images/modal_bg.svg);
                background-repeat: no-repeat;
                background-position: 100% 0;
                width: 100%;
                max-width: 30rem !important;
                padding: 8rem 4rem 3rem !important;
                &-title{
                    margin: 0 0 1em !important;
                }
                &-text{
                    margin: 0 0 2em !important;
                }
            }
        }
        .icon{
            background-image: url(../../images/mail-sent.png);
            background-repeat: no-repeat;
            margin: auto;
            width: 70px;
            height: 70px;
            margin-bottom: .6rem;
            background-size: contain;;
        }
        .modal-body{
            width: 100%;
            max-width: 18rem;
            padding: 2rem 2rem 0.8rem;
            &-title{
                margin: 0 0 0.7em;
            }
            &-text{
                margin: 0 0 0.7em;
            }
        }
      }
  } 
}
  