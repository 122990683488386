.flexxx{
    display: flex;
   
}
.pepperSection{
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    flex-direction: row;
    border: 1px solid #dadfe3;
    margin-bottom: 30px;
    padding: 30px 16px 20px;
}
.pepperSection1{
    display: flex; 
    flex-direction: row;
    padding: 10px 16px 20px;

}
.textsection1{
    background-color: #fafafa;
    padding: 20px;
    margin-left: 5px;
}
.case-study-textsection1{
    /* display: flex; */
    background-color: #fafafa;
    padding: 20px;
    margin-left: 5px;
    /* flex-direction: column; */
    min-height: fit-content !important;
    height: 100%;
}
.imageSection{
    max-width: 20%;
}
.textSection{
    min-width: 90%;
}
.txt_title{
    color: #333;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 15px;
    margin-top: 0;
}
.details{
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 6%;
    gap: 5px;
    color: #005eb8;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
}
.details1{
    display: flex;
    padding-top: 5% !important;
    gap: 5px; 
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}
.read-more{
    border: 0;
    font-size: 100%;
    font-weight: 400;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: baseline;
    color: #fafafa;
}
.case-study-text{
    color: #222;
    font-size: 16px;
    line-height: 1.88;
    /* margin-bottom: 20px; */
    /* max-height: 90px; */
    /* overflow: hidden; */
    /* max-height: 60px; */
}
.read-more::before{
    content: '';
    display: block;
    width: 10%;
    height: 3px;
    background: #fff;
    margin: 0 auto 0.3rem;
}
.more-page{
    background-color:#fafafa ;
    max-height: 250px;
    max-width: 650px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 15px;
    left: 0;
}
.more-pages-head{
    color: #000;
    font-size: 50px;
    font-weight: '600';
}
.more-pages-head{
    color: #000;
    font-size: larger;
    font-weight: '600';
}
.casestudy-title{
    color: #222;
    font-family: inherit!important;
    font-weight: 700;
    line-height: 1.25;
    margin: 10px 0;
    padding-top: 50px;
    font-size: 30px !important;
    
}
.casestudy-title::after{
    background-color: #57b8ff;
    content: "";
    display: block;
    height: 3px;
    margin: 0px 0 0;
    width: 70px;
}
@media (max-width: 450px){
    .case-study-textsection1{
        padding: 20px;
        margin-left: 5px;
        min-height: 750px !important;
    }
    .details1{
        display: flex;
        justify-content: end;
        align-content: end;
        padding-top: 40%;
    }

}
/* @media (max-width: 1110px){
    .case-study-textsection1{
        background-color: #fafafa;
        padding: 20px;
        margin-left: 5px;
        height: '100%' !important;
    }

} */


@media (min-width: 1304px){
    .pepperSection{
        display: flex;
        min-height: 100%;
    }
    .pepperSection1{
        display: flex;
        /* min-height: 100%; */
    }

}
@media (min-width: 1104px){

    .pepperSection1{
        display: flex;
        min-height: 100% !important;
    }

}

@media (max-width: 900px){
    .pepperSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pepperSection1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .imageSection{
        min-width: 100%;
    }
    .imageSectionsm{
        min-width: 100%;
    }
}