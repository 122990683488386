.halthCare{
    padding-left: 13%;
    padding-right: 10%;
    padding-top: 4%;
}
.firstText{
    /* margin: 40px auto 30px; */
    max-width: 970px;
    text-align: center;
    font-size: 32px;
    color: #004485;
    font-family: Sumana,serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.13;
    padding-top: 30px;
}
.imageComtainer{
    padding-bottom: 40px;
}
.mainHealth{
    display: flex;
    width: 100%;
}
.main-case-study{
    padding: 8% 10% 0 13%;
}
@media (max-width: 900px){
    .mainHealth{
        display: flex;
        flex-direction: column-reverse !important;
    }
}
@media (max-width: 500px){
    .mainHealth{
        display: flex;
    }
}
@media (max-width:414px) {
    .main-case-study{
        padding: 35% 2% 0 3%;
    }
}