.testimonial-container {
    margin: auto;
    padding: 20px;
    border: 10px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
    font-size: '18px';
    line-height: 1.78;
    text-align: 'justify';
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    line-height: 1.78;
    text-align: 'justify';
  }
  .testimonial-header h1 {
    font-size: 26px;
    color: #004085;
    line-height: 1.78;
    text-align: 'justify';
  }
  
  .testimonial-content {
    color: #333;
    line-height: 1.78;
    text-align: 'justify';
  }
  
  .testimonial-content p {
    margin: 10px 0;
    line-height: 1.78;
    text-align: 'justify';
  }
  
  .testimonial-author {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .testimonial-logo {
    margin-top: 20px;
  }
  
  .testimonial-logo img {
    max-width: 100px;
  }

  
 
  