$colors: (
    "primary":#1976d2,
    "secondary": #1976d2,
    "accent-primary": #84BDFF,
    "accent-secondary": #C4C4C4,
    "background-light": #FFF
);

$font-weights: (    
    "extra-bold": 800,
    "bold": 700,
    "semi-bold": 600,
    "medium": 500,
);

@function color($color-key){
    @return map-get($colors, $color-key);
}

@function font-weight($font-key){
    @return map-get($font-weights, $font-key)                                                                                                                               
}

@mixin desktop-screen{
    @media (min-width: 992px){
        @content;
    }
}

@mixin tablet-screen{
    @media  (min-width: 768px) {
        @content;
    }
}